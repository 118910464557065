exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tools-blog-post-prompt-generator-tsx": () => import("./../../../src/pages/tools/blog-post-prompt-generator.tsx" /* webpackChunkName: "component---src-pages-tools-blog-post-prompt-generator-tsx" */),
  "component---src-pages-tools-curl-command-generator-tsx": () => import("./../../../src/pages/tools/curl-command-generator.tsx" /* webpackChunkName: "component---src-pages-tools-curl-command-generator-tsx" */),
  "component---src-pages-tools-day-counter-tsx": () => import("./../../../src/pages/tools/day-counter.tsx" /* webpackChunkName: "component---src-pages-tools-day-counter-tsx" */),
  "component---src-pages-tools-german-salary-calculator-tsx": () => import("./../../../src/pages/tools/german-salary-calculator.tsx" /* webpackChunkName: "component---src-pages-tools-german-salary-calculator-tsx" */),
  "component---src-pages-tools-german-vacation-optimizer-tsx": () => import("./../../../src/pages/tools/german-vacation-optimizer.tsx" /* webpackChunkName: "component---src-pages-tools-german-vacation-optimizer-tsx" */),
  "component---src-pages-tools-json-formatter-tsx": () => import("./../../../src/pages/tools/json-formatter.tsx" /* webpackChunkName: "component---src-pages-tools-json-formatter-tsx" */),
  "component---src-pages-tools-pomodoro-timer-tsx": () => import("./../../../src/pages/tools/pomodoro-timer.tsx" /* webpackChunkName: "component---src-pages-tools-pomodoro-timer-tsx" */)
}

